'use client';

import {commonDisableCachingParameters} from '@/common';
import {getNearestStoreData} from '@/selectors';
import {
  useGetAllFiltersQuery,
  useGetAllProductsQuery,
  useGetAllSubCategoriesQuery,
  useLazyGetAllProductsQuery,
} from '@/services';
import {Divider} from '@heroui/react';
import debounce from 'lodash/debounce';
import {useTranslations} from 'next-intl';
import {useParams} from 'next/navigation';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {LuSettings2} from 'react-icons/lu';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {
  CategoryDescription,
  CategoryList,
  Filters,
  MobileCategoryList,
  MobileFilters,
  ProductList,
} from '.';
import {CustomButton} from '../common';

const CategoriesSection = ({subCategory}) => {
  const t = useTranslations();
  const {locale} = useParams();
  const params = useParams();
  const [filterValues, setFilterValues] = useState({});
  const [expandedFilters, setExpandedFilters] = useState(new Set());
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [highestPrice, setHighestPrice] = useState(1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [showenFilter, setShownFilter] = useState(0);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const onHandlingShowingFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };
  const [selectedCategory, setSelectedCategory] = useState(
    params?.slug[params.slug.length - 1],
  );

  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const store = useSelector(getNearestStoreData);

  const isAllSection = useMemo(() => {
    return ['All', 'الكل'].includes(subCategory?.data?.attributes?.name);
  }, [subCategory]);

  const getAllProductsQueryParameters = {
    locale,
    include: 'images',
    per_page: 12,
    page: currentPage,
    'filter[taxons]': isAllSection
      ? +selectedCategory
      : params?.slug[params.slug.length - 1],
    'filter[price]': priceRange.join(','),
    'filter[branches]': store?.id,
    ...Object.entries(filterValues).reduce((acc, [key, values]) => {
      if (Array.isArray(values) && values.length > 0) {
        acc[`filter[options][${key}]`] = values;
      }
      return acc;
    }, {}),
  };

  const {
    data: allProducts,
    isFetching: isGettingProductsLoading,
    error: getProductsError,
    refetch: refetchAllProducts,
  } = useGetAllProductsQuery(
    getAllProductsQueryParameters,
    commonDisableCachingParameters,
  );

  const {
    data: allCategoryProducts,
    isLoading: isGettingAllCategoryProductsLoading,
    error: getAllCategoryProductsError,
  } = useGetAllProductsQuery(
    {sort_by: 'price-low-to-high', locale, 'filter[branches]': store?.id},
    commonDisableCachingParameters,
  );

  const [
    getAllCategoryProducts,
    {data: categoryProducts, isLoading: isGettingCategoryProducts},
  ] = useLazyGetAllProductsQuery();

  const {
    data: filters,
    isLoading: isGettingFiltersLoading,
    error: getFiltersError,
  } = useGetAllFiltersQuery(
    {include: 'option_values', locale},
    commonDisableCachingParameters,
  );

  const getFilterValues = filterId => {
    const optionType = filters?.data.find(option => option.id === filterId);
    if (!optionType) {
      return [];
    }
    const optionValueIds = optionType.relationships.option_values.data.map(
      value => value.id,
    );
    return filters?.included.filter(value => optionValueIds.includes(value.id));
  };

  const refetchAllCategoryProducts = useCallback(async () => {
    if (!isGettingAllCategoryProductsLoading) {
      await getAllCategoryProducts(
        {
          sort_by: 'price-low-to-high',
          locale,
          'filter[branches]': store?.id,
          page: allCategoryProducts?.meta?.total_pages,
        },
        commonDisableCachingParameters,
      );
    }
  }, [isGettingAllCategoryProductsLoading]);

  useEffect(() => {
    if (
      !isGettingAllCategoryProductsLoading &&
      allCategoryProducts?.data?.length > 0
    ) {
      refetchAllCategoryProducts();
      if (!isGettingCategoryProducts && categoryProducts?.data?.length > 0) {
        const maxPrice = Number(
          categoryProducts?.data[categoryProducts.data.length - 1].attributes
            .price,
        );
        setHighestPrice(maxPrice);
        setPriceRange([0, maxPrice]);
      }
    }
  }, [
    isGettingAllCategoryProductsLoading,
    allCategoryProducts,
    highestPrice,
    categoryProducts,
    isGettingCategoryProducts,
  ]);

  useEffect(() => {
    if (allProducts?.data?.length === 0 && currentPage > 1) {
      setCurrentPage(prev => prev - 1);
      refetchAllProducts();
    }
  }, [allProducts]);

  const handleFilterClick = filter => {
    const newExpandedFilters = new Set(expandedFilters);
    if (newExpandedFilters.has(filter)) {
      newExpandedFilters.delete(filter);
    } else {
      newExpandedFilters.add(filter);
    }
    setExpandedFilters(newExpandedFilters);
  };

  const handleCategoryClick = categoryId => {
    setSelectedCategory(categoryId);
  };
  const handleShowingFilterInMobile = id => {
    setShownFilter(id);
  };

  const handlePriceRangeChange = useMemo(() => {
    return debounce(range => {
      setPriceRange(range);
    }, 500);
  }, []);

  const {data: subCategories} = useGetAllSubCategoriesQuery(
    {parentId: '1', locale},
    {skip: !isAllSection, ...commonDisableCachingParameters},
  );

  const filteredCategories = useMemo(() => {
    return subCategories?.data?.filter(
      category =>
        category?.relationships?.parent?.data?.id === subCategory?.data?.id,
    );
  }, [subCategories]);

  useEffect(() => {
    return () => {
      handlePriceRangeChange.cancel();
    };
  }, [handlePriceRangeChange]);

  return (
    <>
      <div className="pb-32">
        <CategoryDescription t={t} subCategory={subCategory} />
        <div className="relative flex justify-end py-4 pe-6 sm:gap-4 sm:pe-[9.5vw] xl:mx-0">
          <div className="flex items-center gap-4">
            <p className="font-secondary font-semibold text-persianIndigo">
              {t('filter_options')}
            </p>
            <CustomButton
              iconOnly
              onClickHandling={onHandlingShowingFilters}
              value={<LuSettings2 className="text-[24px] text-thistle" />}
              btnStyles={`border-[1px] border-solid border-persianIndigo bg-white`}
            />
          </div>
        </div>
        <Filters
          filters={filters}
          handleFilterClick={handleFilterClick}
          setFilterValues={setFilterValues}
          t={t}
          isFiltersVisible={isFiltersVisible}
          expandedFilters={expandedFilters}
          getFilterValues={getFilterValues}
          onPriceRangeChange={handlePriceRangeChange}
          highestPrice={highestPrice}
        />

        <div
          className={`rtl:sm:space-x-0' flex flex-col sm:flex-row sm:!space-x-0 sm:px-[9.5vw] ltr:sm:space-x-8`}>
          <div className="flex sm:flex-col"></div>
          <ProductList
            products={allProducts}
            isLoading={isGettingProductsLoading}
            category={subCategory?.data?.attributes?.permalink}
            t={t}
            isAllSection={isAllSection}
            locale={locale}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currentCategory={subCategory}
            storeId={store?.id}
          />
        </div>
      </div>
    </>
  );
};

export default CategoriesSection;
