'use client';
import {visa} from '@/assets';
import {
  getIsDeleteCreditCardModalOpen,
  getIsUpdateCreditCardModalOpen,
} from '@/selectors/modalActionsSelectors';
import {useGetUserCreditCardsQuery} from '@/services';
import {Button, ScrollShadow} from '@heroui/react';
import Image from 'next/image';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DeleteCreditCardModal, UpdateCreditCardModal} from '.';
import {
  closeDeleteCreditCard,
  closeUpdateCreditCard,
  openDeleteCreditCard,
  openUpdateCreditCard,
} from '@/slices';
import {useLocale, useTranslations} from 'next-intl';
import {CustomButton, LoadingView} from '../common';
import {commonDisableCachingParameters} from '@/common';

const CardsTab = () => {
  const t = useTranslations();
  const locale = useLocale();
  const {
    data: userCreditCards,
    error: getCreditCardsError,
    isLoading: isGettingCreditCardsLoading,
  } = useGetUserCreditCardsQuery({locale}, commonDisableCachingParameters);
  const isUpdateCreditCardModalOpen = useSelector(
    getIsUpdateCreditCardModalOpen,
  );
  const IsDeleteCreditCardModalOpen = useSelector(
    getIsDeleteCreditCardModalOpen,
  );
  const defaultCreditCard = useMemo(
    () => userCreditCards?.filter(card => card.default) || [],
    [userCreditCards],
  );

  const dispatch = useDispatch();

  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = card => {
    setSelectedCard(card);
  };

  const onOpeningUpdateCreditCardModal = () => {
    dispatch(openUpdateCreditCard());
  };
  const onOpeningDeleteCreditCardModal = () => {
    dispatch(openDeleteCreditCard());
  };

  const onClosingDeleteCreditCardModal = () => {
    dispatch(closeDeleteCreditCard());
  };

  const onClosingUpdateCreditCardModal = () => {
    dispatch(closeUpdateCreditCard());
  };

  useEffect(() => {
    onClosingUpdateCreditCardModal();
    onClosingDeleteCreditCardModal();

    if (userCreditCards?.length > 0) {
      setSelectedCard(defaultCreditCard[0] || userCreditCards[0]);
    }
  }, [userCreditCards]);
  useEffect(() => {
    if (selectedCard) {
      const selectedCardModified = userCreditCards.find(
        card => card.id === selectedCard.id,
      );
      setSelectedCard(selectedCardModified);
    }
  }, [userCreditCards, selectedCard]);

  if (isGettingCreditCardsLoading) {
    return <LoadingView isTabs />;
  }

  return (
    <>
      <div>
        {userCreditCards?.length === 0 ? (
          <div className="flex h-full items-center justify-center">
            <p className="font-secondary text-[16px] font-medium text-persianIndigo">
              {t('no_credit_cards_available')}
            </p>
          </div>
        ) : (
          <>
            <div className="mt-[1.875rem] grid grid-cols-1 gap-[2rem] md:grid-cols-2 md:gap-[5rem]">
              <ScrollShadow hideScrollBar>
                <div className="max-h-[280px]">
                  {userCreditCards?.map(card => (
                    <div key={card.id} onClick={() => handleCardClick(card)}>
                      <div className="flex items-center justify-between">
                        <p className="mb-3 font-secondary text-persianIndigo">
                          {card.name}
                        </p>
                        {card.default && (
                          <p className="font-secondary text-persianIndigo">
                            {t('default')}
                          </p>
                        )}
                      </div>

                      <div
                        className={`mb-[25px] cursor-pointer transition ${
                          selectedCard?.id === card.id ? 'border-3' : 'border-1'
                        } border-thistle p-[15px]`}>
                        <p className="font-secondary text-persianIndigo">
                          {t('ending_with')} {card.last_digits}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollShadow>
              <div className="credit-card-img flex h-[285px] flex-col justify-between rounded-xl bg-cover bg-center bg-no-repeat">
                {selectedCard ? (
                  <>
                    <div className="flex justify-between">
                      <h3
                        className={`${
                          locale === 'en' ? 'ml-12' : 'mr-12'
                        } mt-9 font-secondary text-[20px] text-white`}>
                        {selectedCard.default ? t('default') : t('credit')}
                      </h3>
                      <div
                        className={`${
                          locale === 'en' ? 'mr-12' : 'ml-12'
                        } mt-9 self-end`}>
                        <Image src={visa} width={90} alt="visa" height={30} />
                      </div>
                    </div>

                    <div
                      className={`${locale === 'en' ? 'ml-12' : 'mr-12'} mb-9`}>
                      <p className="font-secondary text-[16px] font-bold text-white">
                        {selectedCard?.name}
                      </p>
                      <p className="font-secondary text-[30px] font-bold text-white">{`**** **** ****  ${selectedCard?.last_digits}`}</p>
                      <p className="font-secondary text-[20px] font-bold text-white">{`${selectedCard?.month}/${String(
                        selectedCard?.year,
                      )}`}</p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="mt-[50px] flex flex-col-reverse items-center gap-6 md:flex-row md:justify-between md:gap-0">
              <CustomButton
                btnType="secondary"
                onClickHandling={onOpeningDeleteCreditCardModal}
                btnStyles="w-[192px]"
                value={t('delete')}
                isDisabled={selectedCard ? false : true}
              />

              <CustomButton
                btnStyles="md:self-end w-[192px]"
                isDisabled={selectedCard ? false : true}
                onClickHandling={onOpeningUpdateCreditCardModal}
                value={t('update')}
              />
            </div>
          </>
        )}
      </div>
      {isUpdateCreditCardModalOpen && (
        <UpdateCreditCardModal selectedCard={selectedCard} />
      )}
      {IsDeleteCreditCardModalOpen && (
        <DeleteCreditCardModal selectedCard={selectedCard} />
      )}
    </>
  );
};

export default CardsTab;
