'use client';

import {
  Badge,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollShadow,
  Spinner,
} from '@heroui/react';
import {useLocale, useTranslations} from 'next-intl';
import {useCallback, useMemo, useState} from 'react';
import {CgShoppingBag} from 'react-icons/cg';
import {BsTrash} from 'react-icons/bs';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {handleCartPopUpState, setIsWalletPaymentChosen} from '@/slices';
import {
  getDeliveryMethodId,
  getGuestCredentials,
  getIsCartOpen,
  getToken,
  getUserData,
} from '@/selectors';
import Image from 'next/image';
import {useApplyCartActions, UseGetOrCreateCart} from '@/hooks';
import {cart as shoppingCart, emptyCart, product2} from '@/assets';
import {useRouter} from '@/hooks';
import {CustomButton} from '../common';
import {convertEnglishNumbersToArabic, parseNumber} from '@/common';
import {sendGTMEvent} from '@next/third-parties/google';

const CartPopUp = ({navbar, cart}) => {
  const locale = useLocale();
  const isCartOpen = useSelector(getIsCartOpen);
  const token = useSelector(getToken);
  const guestCredentials = useSelector(getGuestCredentials);
  const router = useRouter();
  const t = useTranslations();
  const dispatch = useDispatch();
  const {debouncedApplyCartAction, applyCartAction, loadingProducts} =
    useApplyCartActions();
  const [touchHandled, setTouchHandled] = useState(false);
  const userData = useSelector(getUserData);

  const doesCartHaveVarients = useMemo(
    () => cart?.lineItems?.length > 0,
    [cart?.lineItems],
  );
  const handleAddItem = useCallback(
    item => {
      dispatch(setIsWalletPaymentChosen(false));
      applyCartAction('add', item.variant);
    },
    [dispatch, applyCartAction],
  );

  const handleDeleteItem = useCallback(
    item => {
      dispatch(setIsWalletPaymentChosen(false));
      debouncedApplyCartAction('delete', item);
    },
    [debouncedApplyCartAction],
  );

  const handleTouchStart = () => {
    setTouchHandled(true);
    onTogglingCart();
  };

  const handleClick = () => {
    if (!touchHandled) {
      onTogglingCart();
    }
    setTouchHandled(false);
  };

  const handleDecreasingVariantQuantity = useCallback(
    item => {
      dispatch(setIsWalletPaymentChosen(false));
      if (item?.quantity <= 1) {
        handleDeleteItem(item);
      } else {
        debouncedApplyCartAction('decrease', item, item?.quantity);
      }
    },
    [debouncedApplyCartAction, handleDeleteItem],
  );

  const onNavigateToCheckout = () => {
    const items = cart.lineItems.map(({name, quantity, price}) => ({
      item_name: name,
      quantity,
      price,
    }));

    sendGTMEvent({
      event: 'begin_checkout',
      ecommerce: {
        value: cart?.total,
        currency: 'SAR',
        items,
      },
      ...(token &&
        userData?.userName && {
          name: userData.userName,
          email: userData.userEmail,
          phone_number: userData.userPhone,
        }),
    });

    dispatch(setIsWalletPaymentChosen(false));
    router.push(
      `/checkout/${token || guestCredentials.isDataValid ? '2' : '1'}`,
    );
    onClosingCart();
  };
  const onClosingCart = () => {
    dispatch(handleCartPopUpState(false));
  };
  const onTogglingCart = () => {
    dispatch(handleCartPopUpState(!isCartOpen));
  };
  const onHandlingClicksOutsideOfCartBoundaries = useCallback(element => {
    if (element?.classList?.contains('popOverTrigger')) {
      return true;
    }
    dispatch(handleCartPopUpState(false));
    return false;
  }, []);
  return (
    <div>
      <Popover
        portalContainer={navbar.current}
        isOpen={isCartOpen}
        shouldCloseOnInteractOutside={element =>
          onHandlingClicksOutsideOfCartBoundaries(element)
        }
        offset={20}
        classNames={{
          content:
            'z-[9999] p-0 w-[22rem] border-[0.031rem] border-thistle !shadow-none',
        }}>
        <PopoverTrigger>
          <div
            onClick={handleClick}
            onTouchStart={handleTouchStart}
            className={`${locale === 'en' ? 'sm:w-[1.5rem]' : 'sm:w-[1rem]'} popOverTrigger cursor-pointer sm:mr-[-0.25rem] xl:w-[2rem] rtl:ml-4 rtl:sm:ml-0`}>
            <Badge
              aria-label="cart badge"
              content={cart?.itemCount}
              classNames={{
                badge: `bg-pink !text-white mt-2 sm:p-1 border-none sm:scale-80 xl:scale-100 popOverTrigger`,
              }}>
              <div className="h-6 w-6">
                <Image
                  src={shoppingCart}
                  width={56}
                  height={56}
                  alt="cart"
                  className="popOverTrigger relative z-[6] mt-[0.125rem] cursor-pointer"
                />
              </div>
            </Badge>
          </div>
        </PopoverTrigger>

        <PopoverContent>
          <div className="relative z-[100] flex w-full flex-col p-8">
            <div
              className={`arrow-up absolute top-[-1.25rem] sm:top-[-1.25rem] ltr:right-[3.125rem] ltr:sm:right-[3.25rem] ltr:xl:right-[9.5rem] rtl:left-[1.125rem] rtl:sm:left-[3.75rem] rtl:xl:left-[10rem]`}
            />
            <h3 className="border-b-[0.031rem] border-thistle pb-[10px] text-left font-main font-medium text-persianIndigo sm:text-[14px] xl:text-[18px]">
              {t('shopping_cart')}
            </h3>
            {doesCartHaveVarients ? (
              <>
                <ScrollShadow
                  hideScrollBar
                  isEnabled={false}
                  className="mb-[15px]">
                  <div className="max-h-[40vh]">
                    <div className="mb-4 flex flex-col">
                      {cart?.lineItems?.map((item, index) => (
                        <div key={index} className="flex gap-4 py-2">
                          <div className="relative w-[145px] rounded">
                            <Image
                              src={
                                item?.variant?.images?.[0]?.originalUrl ||
                                item?.productImages?.[index]?.images?.[0]
                                  ?.originalUrl ||
                                product2
                              }
                              layout="fill"
                              className="rounded-md"
                              objectFit="cover"
                              alt={item?.name}
                            />
                          </div>
                          <div
                            className={`flex w-full flex-col justify-between`}>
                            <div className="align-start flex justify-between">
                              <div>
                                <h6 className="text-ellipsis font-main font-medium text-persianIndigo sm:text-[10px] xl:text-[12px]">
                                  {item?.name}
                                </h6>
                                <div className="flex gap-5">
                                  <span className="font-main text-[10px] font-medium text-persianIndigo">
                                    {`${convertEnglishNumbersToArabic(item?.displayPrice.split('.')[0], locale)} ${t('sar')}`}
                                  </span>
                                  <span className="font-main text-[10px] font-medium capitalize text-persianIndigo">
                                    {item?.optionsText.split(' ')[1]}
                                  </span>
                                </div>
                              </div>
                              {item?.quantity > 1 && (
                                <BsTrash
                                  className="mt-[5px] cursor-pointer"
                                  onClick={() => handleDeleteItem(item)}
                                  size={18}
                                />
                              )}
                            </div>
                            <div className="flex items-center justify-between rounded bg-lightPurple px-2 py-3">
                              <div
                                className="cursor-pointer rounded bg-white p-2"
                                onClick={() =>
                                  handleDecreasingVariantQuantity(item)
                                }>
                                {item?.quantity === 1 ? (
                                  <BsTrash className="text-black" />
                                ) : (
                                  <FaMinus className="text-black" />
                                )}
                              </div>
                              <div className="relative">
                                {loadingProducts.has(item?.variant.id) ? (
                                  <Spinner
                                    color="secondary"
                                    variant="dots"
                                    classNames={{
                                      base: 'absolute bottom-[-14px] right-[-14px] ',
                                    }}
                                  />
                                ) : (
                                  <span className="font-main text-[12px] font-bold text-persianIndigo">
                                    {convertEnglishNumbersToArabic(
                                      item?.quantity,
                                      locale,
                                    )}
                                  </span>
                                )}
                              </div>
                              <div
                                className="cursor-pointer rounded bg-persianIndigo p-2"
                                onClick={() => handleAddItem(item)}>
                                <FaPlus className="text-white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="flex flex-col gap-2 border-b-[0.031rem] border-t-[0.031rem] border-thistle py-4">
                      <div className="flex justify-between">
                        <span className="font-secondary text-[14px] text-persianIndigo">
                          {t('subtotal')}
                        </span>
                        <span className="font-secondary text-[11px] text-persianIndigo">
                          {convertEnglishNumbersToArabic(
                            parseNumber(cart?.displayPreTaxTotal).toFixed(1),
                            locale,
                          )}{' '}
                          {t('sar')}
                        </span>
                      </div>
                    </div>

                    <div className="my-4 flex justify-between">
                      <span className="font-secondary text-[18px] font-bold text-persianIndigo">
                        {t('total')}
                      </span>
                      <span className="font-secondary text-[13px] font-bold text-persianIndigo">
                        {convertEnglishNumbersToArabic(cart?.total, locale)}{' '}
                        {t('sar')}
                      </span>
                    </div>
                  </div>
                </ScrollShadow>

                <div className="flex flex-col gap-4">
                  <CustomButton
                    onClickHandling={onNavigateToCheckout}
                    btnStyles="h-[2.688rem]"
                    value={t('checkout')}
                    id="checkout-button-id"
                  />
                  <CustomButton
                    onClickHandling={onClosingCart}
                    btnType="secondary"
                    btnStyles="h-[2.688rem]"
                    value={t('continue_shopping')}
                  />
                </div>
              </>
            ) : (
              <div>
                <div className="flex h-[14rem] flex-col items-center justify-center">
                  <Image
                    src={emptyCart}
                    width={40}
                    height={55}
                    alt="empty cart"
                    priority
                  />
                  <h2 className="my-5 text-center font-secondary text-[12px] font-bold text-thistle">
                    {t('your_cart_is_empty')}
                  </h2>
                </div>
                <div>
                  <CustomButton
                    btnType="secondary"
                    btnStyles="h-[2.688rem] w-full"
                    value={t('continue_shopping')}
                    onClickHandling={onClosingCart}
                  />
                </div>
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default CartPopUp;
