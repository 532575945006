'use client';

import React, {useMemo} from 'react';
import {
  useGetAllCategoriesQuery,
  useGetAllCategoriesWithTheirChildrenQuery,
} from '@/services';
import {useLocale} from 'next-intl';
import {
  HomeShopByOcassions,
  HomeOurProducts,
  BestSellingSection,
  NewArrivalSection,
  HomeNewIdeasSection,
  Partners,
  HomeRibbon,
  HomeArrangmentsSection,
  FlowerFoodSection,
  HomeSRFeatures,
  HomeHeroView,
} from '@/components/Home';
import {FloatingIcon} from '../common';

const HomePage = () => {
  const locale = useLocale();
  const {data: categories} = useGetAllCategoriesQuery({
    include: 'image',
    locale: locale,
    per_page: 100,
  });

  const {data: categoriesWithTheirChildren} =
    useGetAllCategoriesWithTheirChildrenQuery({locale, per_page: 100});

  const occasionsCategory = useMemo(() => {
    return categoriesWithTheirChildren?.find(
      category => category.isOccasionsCategory,
    );
  }, [categoriesWithTheirChildren]);

  const categoryMap = useMemo(() => {
    const map = {
      freshPicksCategory: null,
      bestSellingCategory: null,
      newArrivalCategory: null,
    };

    categories?.data?.forEach(category => {
      if (category.attributes.fresh_picks) {
        map.freshPicksCategory = category;
      }
      if (category.attributes.best_selling) {
        map.bestSellingCategory = category;
      }
      if (category.attributes.new_arrival) {
        map.newArrivalCategory = category;
      }
    });

    return map;
  }, [categories]);

  const {freshPicksCategory, bestSellingCategory, newArrivalCategory} =
    categoryMap;

  return (
    <div>
      <HomeHeroView />
      <HomeShopByOcassions category={occasionsCategory} />
      <BestSellingSection category={bestSellingCategory} />
      <NewArrivalSection category={newArrivalCategory} />
      <HomeOurProducts category={freshPicksCategory} />
      <HomeNewIdeasSection />
      <Partners />
      <HomeRibbon />
      <HomeArrangmentsSection />
      <FlowerFoodSection />
      <HomeSRFeatures />
      <FloatingIcon />
    </div>
  );
};
export default HomePage;
