'use client';

import {leftCategoryFlower, rightCategoryFlower} from '@/assets';
import {capitalizeEachWord, transformString} from '@/common';
import {useLocale} from 'next-intl';
import Image from 'next/image';
import {useParams} from 'next/navigation';
import {useMemo} from 'react';

const CategoryDescription = ({t, subCategory}) => {
  const locale = useLocale();
  const {slug} = useParams();

  const arabicCategoryName = useMemo(
    () => subCategory?.data?.attributes?.pretty_name.split('->')[0].trim(),
    [subCategory],
  );
  return (
    <div className="relative mb-[50px] mt-[2rem] flex h-[9vh] flex-col justify-center rounded-[8px] bg-darkWhite sm:mx-[13.5vw] sm:mt-[3rem] sm:h-[18.5vh] sm:flex-row sm:items-center xl:gap-[4rem]">
      <div className="absolute h-[13.63vh] w-[32.55vw] sm:right-[-74px] sm:h-[22.42vh] sm:w-[13.90vw] ltr:right-0 rtl:left-0">
        <Image fill src={leftCategoryFlower} alt="left category Flower" />
      </div>
      <div className="absolute left-[-74px] sm:h-[22.42vh] sm:w-[13.90vw]">
        <Image fill src={rightCategoryFlower} alt="right Category Flower" />
      </div>
      <div className="flex-col items-center justify-center">
        <h2 className="px-6 font-secondary text-[20px] font-semibold text-persianIndigo sm:px-0 sm:text-center sm:text-[24px]">
          {subCategory?.data?.attributes?.name}
        </h2>
        <nav className="flex items-center px-6 font-secondary text-[12px] font-normal text-persianIndigo sm:justify-center sm:px-0 sm:text-[11px] xl:text-[0.8vw]">
          {`${t('home')}`}
          {slug.length > 2 ? (
            <>
              <span className="mx-1 text-[18px]">&gt;</span>{' '}
              {`${locale === 'en' ? capitalizeEachWord(transformString(arabicCategoryName)) : arabicCategoryName}`}
              <span className="mx-1 text-[18px]">&gt;</span>{' '}
              {`${subCategory?.data?.attributes?.name}`}
            </>
          ) : (
            <>
              <span className="mx-1 text-[18px]">&gt;</span>{' '}
              {`${subCategory?.data?.attributes?.name}`}
            </>
          )}
        </nav>
      </div>
    </div>
  );
};

export default CategoryDescription;
