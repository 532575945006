'use client';

import {
  carbon,
  expressDelivery,
  giftWrapping,
  grower,
  namedDay,
} from '@/assets';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import React from 'react';

const HomeSRFeatures = () => {
  const t = useTranslations();

  const srFeat = [
    {img: expressDelivery, text: t('express_delivery')},
    {img: giftWrapping, text: t('complimentary_gift_wrapping')},
    {img: namedDay, text: t('named_day_delivery')},
    {img: grower, text: t('direct_from_the_grower')},
    {
      img: carbon,
      text: t('carbon_neutral'),
    },
  ];

  return (
    <div className="mx-[2rem] my-[6rem] sm:mx-[6rem] xl:mt-0">
      <p className="mb-8 text-center font-bentogaThin text-[20px] font-medium text-spanishViolet sm:text-start sm:text-[36px]">
        {t('explore_the_timeless_beauty_of_springe_rose')}
      </p>
      <div className="flex justify-between sm:justify-center">
        {srFeat.map((item, index) => (
          <div key={index} className="flex flex-col items-center gap-4 sm:px-4">
            <Image
              src={item.img}
              alt={item.text}
              className="mx-auto h-[58px] w-[45px] sm:h-[68px] sm:w-[75px]"
              height={70}
              width={50}
            />
            <p className="text-center font-secondary text-[9px] text-spanishViolet sm:text-[16px]">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeSRFeatures;
