'use client';

import {convertEnglishNumbersToArabic, renderStars} from '@/common';
import {getNearestStoreData} from '@/selectors';
import {useGetAllFreshPicksProductsQuery} from '@/services';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import React, {Fragment, useState} from 'react';
import {motion} from 'framer-motion';
import {useSelector} from 'react-redux';
import {branch, product2} from '@/assets';
import {useDisclosure} from '@heroui/react';
import ProductQuickView from './ProductQuickView';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {UseAddOrRemoveFav, UseHandleControlledSwipe} from '@/hooks';
import {CustomButton} from '../common';
import {GoHeart, GoHeartFill} from 'react-icons/go';
import {useMediaQuery} from 'react-responsive';
import Link from 'next/link';

const HomeOurProducts = ({category}) => {
  const locale = useLocale();
  const t = useTranslations();
  const store = useSelector(getNearestStoreData);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const {onOpen, isOpen, onOpenChange, onClose} = useDisclosure();
  const variants = {
    initial: {y: 75},
    animate: {y: 0},
  };
  const {
    applyOrRemoveFavItem,
    localFavData,
    isFavProductsAreLoading,
    favToken,
  } = UseAddOrRemoveFav();
  const {data: allProducts, error: getBestSellingError} =
    useGetAllFreshPicksProductsQuery(
      {
        locale,
        'filter[branches]': store?.id,
        'filter[taxons]': category?.id,
        per_page: 8,
      },
      {
        skip: !store?.id,
      },
    );
  const onSavingSelectedProductState = product => {
    setSelectedProduct(product);
    onOpen();
  };
  const onHandlingFavoriteList = product => {
    const isProductInFavorites = localFavData?.find(
      item => item?.product.id === product?.id,
    );
    return isProductInFavorites;
  };

  const onHandlingFavActions = product => {
    applyOrRemoveFavItem(
      onHandlingFavoriteList(product),
      product?.variants[0]?.id,
      product?.id,
    );
  };
  const {emblaRef} = UseHandleControlledSwipe({
    dragFree: true,
    containScroll: 'trim',
    speed: 12,
  });
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  if (allProducts?.products.length > 0) {
    return (
      <div>
        <div className="mb-[2vw] mt-[6.3vw] flex items-center justify-between pe-4 ps-8 sm:px-[9vw]">
          <div className="text-center">
            <p className="font-bentogaThin text-[20px] font-medium text-spanishViolet sm:text-[clamp(16px,2.86vw,55px)]">
              {t('our_products')}
            </p>
          </div>
          <Link
            href={`/${locale}/category/${category?.attributes.permalink}/${category?.id}`}>
            <div className="flex cursor-pointer items-center gap-1">
              <h3 className="border-b-1 border-persianIndigo font-secondary font-normal text-persianIndigo sm:text-[clamp(16px,1.56vw,30px)]">
                {t('view_all')}
              </h3>
              {locale === 'ar' ? (
                <MdKeyboardArrowLeft className="text-[16px] text-persianIndigo sm:text-[24px]" />
              ) : (
                <MdKeyboardArrowRight className="text-[16px] text-persianIndigo sm:text-[24px]" />
              )}
            </div>
          </Link>
        </div>
        <div ref={isMobile ? emblaRef : null} className="overflow-hidden">
          <div className="me-4 ms-7 flex justify-around gap-4 sm:me-0 sm:ms-0 sm:grid sm:grid-cols-4 sm:gap-[1.8vw] sm:px-[9.17vw]">
            {allProducts?.products
              ?.filter(product => product.inStock)
              ?.reduce((acc, product, index, array) => {
                if (index % 2 === 0) {
                  acc.push([product, array[index + 1]]);
                }
                return acc;
              }, [])
              ?.map((pair, index) => (
                <div key={index} className="flex flex-col gap-4">
                  {pair.map(
                    (product, i) =>
                      product && (
                        <Fragment key={product?.id}>
                          <div
                            className="group relative box-border h-[11.875rem] w-[37.44vw] sm:h-[19.8vw] sm:w-[18.8vw]"
                            key={i}>
                            <motion.div
                              initial="initial"
                              animate="initial"
                              whileHover="animate"
                              className="relative flex h-[11.875rem] w-[37.44vw] cursor-pointer flex-col justify-end overflow-hidden sm:h-[19.8vw] sm:w-[18.8vw]">
                              <Link
                                href={`/${locale}/${category?.attributes?.permalink}/${product.slug}/${product.id}?sid=${store?.id}`}>
                                <Image
                                  sizes="33vw"
                                  fill
                                  className="h-[11.875rem] w-[37.44vw] rounded-[16px] sm:h-[19.8vw] sm:w-[18.8vw]"
                                  alt={product?.name}
                                  src={
                                    product?.images?.length > 0
                                      ? product?.images[0]?.originalUrl
                                      : product2
                                  }
                                />
                              </Link>

                              <motion.div>
                                {product?.variants.length > 1 && (
                                  <motion.p
                                    variants={variants}
                                    className="group-hover relative z-[100] mb-[0.75rem] inline-block bg-white bg-opacity-50 px-2 py-1 font-secondary text-[14px] text-black">
                                    {t('more_colors')}
                                  </motion.p>
                                )}
                                {favToken && (
                                  <div className="absolute top-0 sm:top-[10px] ltr:right-[4px] ltr:sm:right-[10px] rtl:left-[4px] rtl:sm:left-[10px]">
                                    <CustomButton
                                      iconOnly
                                      isLoading={isFavProductsAreLoading}
                                      btnStyles="!bg-transparent cursor-pointer"
                                      onClickHandling={() =>
                                        onHandlingFavActions(product)
                                      }
                                      value={
                                        onHandlingFavoriteList(product) ? (
                                          <GoHeartFill className="text-[22px] font-bold text-persianIndigo" />
                                        ) : (
                                          <GoHeart className="text-[22px] font-bold text-spanishViolet" />
                                        )
                                      }
                                    />
                                  </div>
                                )}

                                <motion.h1
                                  className="group-hover relative rounded-b-[16px] bg-spanishViolet bg-opacity-50 py-3 text-center font-secondary text-[20px] font-thin text-white"
                                  variants={variants}
                                  onClick={() =>
                                    onSavingSelectedProductState(product)
                                  }>
                                  {t('quick_view')}
                                </motion.h1>
                              </motion.div>
                            </motion.div>
                          </div>
                          <div className="-mt-2 flex-col">
                            <div className="flex items-center justify-between">
                              <h3 className="font-secondary text-[12px] font-normal text-persianIndigo sm:text-[clamp(12px,0.83vw,16px)]">
                                {product?.name}
                              </h3>
                              {parseInt(product?.totalRating) !== 0 && (
                                <div className="mt-[2px] flex items-center">
                                  <div className="flex">
                                    {renderStars(product?.totalRating)}
                                    <span className="font-secondary text-[16px] font-normal text-persianIndigo sm:text-[clamp(12px,0.83vw,16px)]">
                                      {product?.totalRating}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <p className="font-secondary text-[13px] font-normal text-persianIndigo sm:text-[clamp(12px,0.83vw,16px)] xl:text-[16px]">
                              {convertEnglishNumbersToArabic(
                                product?.price,
                                locale,
                              )}{' '}
                              {`${t('sar')}`}
                            </p>
                          </div>
                        </Fragment>
                      ),
                  )}
                </div>
              ))}
          </div>
        </div>

        {isOpen && (
          <ProductQuickView
            selectedProduct={selectedProduct}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onClose={onClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="relative">
        <Image
          width={230}
          height={190}
          alt="branch"
          className="absolute left-[-3.125rem] top-[-1.625rem] z-[-1]"
          src={branch}
        />
      </div>
    );
  }
};

export default HomeOurProducts;
