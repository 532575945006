'use client';
import {convertEnglishNumbersToArabic} from '@/common';
import {UseAddOrRemoveFav, UseHandleControlledSwipe} from '@/hooks';
import {getNearestStoreData} from '@/selectors';
import {useGetAllNewArrivalsQuery} from '@/services';
import {useLocale, useTranslations} from 'next-intl';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {useSelector} from 'react-redux';
import {CustomButton} from '../common';
import Image from 'next/image';
import {product2} from '@/assets';
import {GoHeart, GoHeartFill} from 'react-icons/go';
import Link from 'next/link';

const NewArrivalSection = ({category}) => {
  const t = useTranslations();
  const store = useSelector(getNearestStoreData);

  const locale = useLocale();
  const {data: allProducts, error: getBestSellingError} =
    useGetAllNewArrivalsQuery(
      {locale, 'filter[branches]': store?.id, 'filter[taxons]': category?.id},
      {
        skip: !store?.id,
      },
    );
  const {
    applyOrRemoveFavItem,
    localFavData,
    isFavProductsAreLoading,
    favToken,
  } = UseAddOrRemoveFav();
  const {emblaRef, emblaApi} = UseHandleControlledSwipe();

  const onHandlingFavoriteList = product => {
    const isProductInFavorites = localFavData?.find(
      item => item?.product.id === product?.id,
    );
    return isProductInFavorites;
  };

  const onHandlingFavActions = product => {
    applyOrRemoveFavItem(
      onHandlingFavoriteList(product),
      product?.variants[0]?.id,
      product?.id,
    );
  };

  return (
    allProducts?.products.length > 0 && (
      <div className="bg-porcelain py-10">
        <div className="mb-[2vw] mt-[3.37vw] flex items-center justify-between pe-4 ps-8 sm:px-[9vw]">
          <div className="text-center">
            <p className="font-bentogaThin text-[20px] font-medium text-spanishViolet sm:text-[clamp(16px,2.86vw,55px)]">
              {category?.attributes?.name}
            </p>
          </div>
          <Link
            href={`/${locale}/category/${category?.attributes.permalink}/${category?.id}`}>
            <div className="flex cursor-pointer items-center gap-1">
              <h3 className="border-b-1 border-persianIndigo font-secondary font-normal text-persianIndigo sm:text-[clamp(16px,1.56vw,30px)]">
                {t('view_all')}
              </h3>
              {locale === 'ar' ? (
                <MdKeyboardArrowLeft size={16} className="text-persianIndigo" />
              ) : (
                <MdKeyboardArrowRight
                  size={16}
                  className="text-persianIndigo"
                />
              )}
            </div>
          </Link>
        </div>
        <div className="relative ms-7 sm:ms-0 xl:px-[9.17vw] rtl:text-right">
          <div className="w-full overflow-hidden" ref={emblaRef}>
            <div className={`me-4 ms-3 flex gap-4 sm:me-0 sm:ms-0`}>
              {allProducts?.products?.map((product, index) => {
                if (!product.inStock) {
                  return null;
                }

                return (
                  <div
                    className="relative flex h-[46.7vw] min-w-[96%] gap-[20px] rounded-[16px] bg-[white] px-[1.66vw] py-[1.5vw] sm:h-[17.7vw] sm:min-w-[33.85vw]"
                    key={product?.id}
                    suppressHydrationWarning={true}>
                    <div className="relative w-[37.44vw] overflow-hidden sm:h-[14.7vw] sm:w-[14.7vw]">
                      <Image
                        alt={product?.name}
                        className="h-full w-full rounded-[16px]"
                        src={
                          product?.images?.length > 0
                            ? product?.images[0]?.originalUrl
                            : product2
                        }
                        sizes="33vw"
                        fill
                      />

                      <span className="absolute left-3 top-3 z-50 rounded-[16px] bg-royalPurple px-3 py-1 font-secondary text-[0.8rem] text-white">
                        {t('new')}
                      </span>
                      {favToken && (
                        <CustomButton
                          iconOnly
                          btnStyles="!bg-transparent !rounded-sm cursor-pointer absolute z-50 right-1 top-2 "
                          isLoading={isFavProductsAreLoading}
                          onClickHandling={() => onHandlingFavActions(product)}
                          value={
                            onHandlingFavoriteList(product) ? (
                              <GoHeartFill className="text-[22px] font-bold text-persianIndigo" />
                            ) : (
                              <GoHeart className="text-[22px] font-bold text-persianIndigo" />
                            )
                          }
                        />
                      )}
                    </div>
                    <div className="my-2 flex w-[50%] flex-col justify-between gap-2 overflow-scroll scrollbar-hide">
                      <div>
                        <p className="font-secondary text-[clamp(14px,1.04vw,20px)] font-medium text-persianIndigo">
                          {product?.name}
                        </p>
                        <p className="font-secondary text-[clamp(12px,0.83vw,16px)] font-normal text-persianIndigo">
                          {convertEnglishNumbersToArabic(
                            product?.price,
                            locale,
                          )}{' '}
                          {`${t('sar')}`}
                        </p>
                        <p className="mt-[0.65vw] h-[21vw] overflow-scroll break-words font-secondary text-[clamp(12px,0.83vw,16px)] font-normal text-dimGray scrollbar-hide sm:h-[7vw]">
                          {product?.description?.replace(/<\/?[^>]+(>|$)/g, '')}
                        </p>
                      </div>
                      <Link
                        href={`/${locale}/${category?.attributes?.permalink}/${product.slug}/${product.id}?sid=${store?.id}`}>
                        <button className="ms-[2.3vw] flex items-center gap-2">
                          <p className="font-secondary text-[clamp(14px,1.04vw,20px)] font-medium text-persianIndigo">
                            {t('shop_now')}
                          </p>
                          {locale === 'ar' ? (
                            <MdKeyboardArrowLeft
                              size={16}
                              className="text-persianIndigo"
                            />
                          ) : (
                            <MdKeyboardArrowRight
                              size={16}
                              className="text-persianIndigo"
                            />
                          )}
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="hidden justify-center gap-2 sm:mt-8 sm:flex">
          {allProducts?.products?.map((_, index) => (
            <button
              key={index}
              className={`h-1 ${index == allProducts?.products?.length - 1 ? 'w-[4.5rem]' : 'w-[1.5rem]'} rounded-[16px] bg-white`}
              onClick={() => emblaApi.scrollTo(index)}
            />
          ))}
        </div>
      </div>
    )
  );
};
export default NewArrivalSection;
