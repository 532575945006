import {flowerFood} from '@/assets';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import React from 'react';

const FlowerFoodSection = () => {
  const t = useTranslations();
  return (
    <div className="relative bottom-[95px] z-[-1] mb-[-135px] flex justify-between sm:bottom-[300px] sm:mb-[-250px]">
      <div className="flex w-[50%] flex-col items-start justify-start gap-4 bg-thistle ps-[2rem] text-start sm:items-center sm:gap-[2.5rem] sm:ps-[0]">
        <p className="my-auto py-[2.5rem] font-bentogaThin text-[14px] text-white sm:text-[48px] xl:py-0 xl:leading-[6rem]">
          {t('we_offer_complimentary')}
          <br />
          <span className="text-[30px] text-royalPurple sm:text-[60px] xl:text-[108px]">
            {t('flower_food')}
          </span>
          <br />
          {t('with_all_our_bouquets')}
        </p>
      </div>
      <div className="w-[50%]">
        <Image
          src={flowerFood}
          className="h-full w-full object-cover sm:object-fill"
          height={700}
          width={700}
          alt="flower food"
        />
      </div>
    </div>
  );
};

export default FlowerFoodSection;
