import React from 'react';
import {CustomButton} from '../common';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {arrangment} from '@/assets';
import Link from 'next/link';

const HomeArrangmentsSection = () => {
  const t = useTranslations();
  const locale = useLocale();
  return (
    <div className="mb-[2.5rem] ms-[2rem] mt-[4rem] flex justify-center sm:mx-[6rem] sm:mt-0 xl:mb-0">
      <div className="flex flex-col items-center gap-4 sm:items-start sm:gap-[2.5rem] xl:w-[50%]">
        <p className="font-bentogaThin text-[20px] text-spanishViolet sm:text-[48px] sm:leading-[4rem] xl:text-[60px]">
          {t('arrangement_by_your_requests')}
        </p>
        <Link href={`/${locale}/contact`}>
          <CustomButton
            btnStyles="bg-spanishViolet h-[40px] sm:h-[56px] w-full xl:mx-0 font-secondary sm:w-[6.5rem] min-w-[10.5rem] sm:min-w-[14.5rem] !rounded-[30px] sm:text-[16px]"
            value={t('contact_us')}
          />
        </Link>
      </div>
      <div className="relative bottom-[14px] sm:bottom-[95px] sm:right-[50px] xl:w-[50%]">
        <Image
          src={arrangment}
          className="sm:h-[700px] sm:w-[700px]"
          height={700}
          width={700}
          alt="arrangment"
        />
      </div>
    </div>
  );
};

export default HomeArrangmentsSection;
