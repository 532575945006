'use client';
import React from 'react';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {branch, product2} from '@/assets';
import {CustomButton} from '../common';
import {UseHandleControlledSwipe} from '@/hooks';
import Link from 'next/link';

const HomeShopByOcassions = ({category}) => {
  const locale = useLocale();
  const t = useTranslations();
  const {emblaRef, canScrollNext, canScrollPrev, emblaApi} =
    UseHandleControlledSwipe({
      dragFree: true,
      containScroll: 'trim',
      speed: 12,
    });

  const renderSubCategory = (child, index) => {
    if (child.name?.toLowerCase() === 'all' || child.name === 'الكل') {
      return null;
    }
    return (
      <Link
        href={`/${locale}/category/${child?.permaLink}/${child?.id}`}
        key={index}>
        <div className="embla__slide flex cursor-pointer flex-col items-center">
          <div
            className={`sm:rounded-0 flex h-[75px] w-[75px] items-center justify-center rounded-full bg-antiFlashWhite sm:h-[6vw] sm:w-[6vw] sm:bg-transparent`}>
            <Image
              src={
                child?.subCategoryImage?.originalUrl
                  ? child?.subCategoryImage?.originalUrl
                  : product2
              }
              width={600}
              height={800}
              alt="product view"
              className="h-[70%] w-[70%] object-fill sm:h-full sm:w-full"
            />
          </div>
          <p className="mt-[2.24vw] text-center font-secondary text-[clamp(12px,1.04vw,20px)] font-medium text-spanishViolet sm:w-[6vw]">
            {child?.name}
          </p>
        </div>
      </Link>
    );
  };

  if (category?.children?.length > 0) {
    return (
      <>
        <div className="mb-[2vw] ms-8 mt-[3.37vw] flex items-center sm:ms-0 sm:justify-between sm:px-[9vw]">
          <div className="text-center">
            <p className="font-bentogaThin text-[20px] font-medium text-spanishViolet sm:text-[clamp(16px,2.86vw,55px)]">
              {t('shop_by_occassions')}
            </p>
          </div>
          <div className="hidden gap-[0.73vw] sm:flex sm:items-center">
            <CustomButton
              iconOnly
              isDisabled={!canScrollPrev}
              onClickHandling={() => emblaApi.scrollPrev()}
              radius="full"
              btnStyles={`cursor-pointer  min-w-[2vw] max-w-[2vw] h-[2vw] bg-persianIndigo z-10 cursor-pointer text-[22px] text-white ${
                !canScrollPrev ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              value={
                locale === 'ar' ? (
                  <MdKeyboardArrowRight />
                ) : (
                  <MdKeyboardArrowLeft />
                )
              }
            />
            <CustomButton
              iconOnly
              isDisabled={!canScrollNext}
              onClickHandling={() => emblaApi.scrollNext()}
              radius="full"
              btnStyles={`cursor-pointer min-w-[2vw] max-w-[2vw] h-[2vw] bg-persianIndigo z-10 cursor-pointer text-[22px] text-white ${
                !canScrollNext ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              value={
                locale === 'ar' ? (
                  <MdKeyboardArrowLeft />
                ) : (
                  <MdKeyboardArrowRight />
                )
              }
            />
          </div>
        </div>
        <div
          className="embla mb-[8.5vw] ms-8 overflow-hidden sm:mb-[6.2vw] sm:px-[9.17vw]"
          ref={emblaRef}>
          <div className="embla__container flex gap-[8vw]">
            {category?.children?.map(renderSubCategory)}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="relative">
        <Image
          width={230}
          height={190}
          alt="branch"
          className="absolute left-[-3.125rem] top-[-1.625rem] z-[-1]"
          src={branch}
        />
      </div>
    );
  }
};

export default HomeShopByOcassions;
